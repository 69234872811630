import axios from "axios"
const url = 'https://dev.pmwevents.com';
// const testurl = 'http://localhost:4100'


const getAttendees = (eventId) => {
  console.log(eventId)
  const event = {
    event: eventId
  }
  console.log(event)

  return axios
  .post(`${url}/events/get-attendees`, event)
  .then((res) => res.data)
  .catch((err) => {
    console.log(`Could not get ateendeed: ${err}`)
    return []
  });
};

const getActiveEvents = () => {
  return axios
  .get(`${url}/events/get-active`)
  .then((res) => res.data)
  .catch((err) => {
    console.log(`Could not get active events: ${err}`)
    return []
  });
}

const getAllEvents = () => {
  return axios
  .get(`${url}/events/get-all`)
  .then((res) => res.data)
  .catch((err) => {
    console.log(`Could not get active events: ${err}`)
    return []
  })
}

const getEventTypes = () => {
  return axios
  .get(`${url}/events/types`)
  .then((res) => res.data)
  .catch((err) => {
    console.log(`Could not get event Types: ${err}`)
    return[]
  })
}

const getAllAttendees = (eventId) => {
  const event = {
    event: eventId
  }

  return axios
  .post(`${url}/events/get-all-attendees`, event)
  .then((res) => res.data)
  .catch((err) => {
    console.log(`Could not get ateendeed: ${err}`)
    return []
  });

}

const getSbcActive = () => {
  return axios.get(`${url}/sbc/get-active-sbc`)
  .then((res) => res.data)
  .catch((err) => {
    console.log('Could not get active SBC events')
    return[]
  })
}

const getSbcById = (eventId) => {
  const event = {
    event: eventId
  }
  return axios.post(`${url}/sbc/get-sbc-event`, event)
  .then((res) => res.data)
  .catch((err) => {
    console.log('Could not get details of SBC Event')
    return []
  })
}

const getSbcAtts = (eventId) => {
  const event = {
    event: eventId
  }

  return axios.post(`${url}/sbc/get-attendees`, event)
  .then((res) => res.data)
  .catch((err) => {
    console.log('Could not get attendees');
    return [];
  })
}

export {getAttendees, getActiveEvents, getAllEvents, getEventTypes, getAllAttendees,
  getSbcActive, getSbcById, getSbcAtts,
}