import Footer from "../../Footer";
import Header from "../../Header";
import '../../../styles/sbcTickets.scss';
import logo from '../../../images/sbc_logo.webp';
import { useEffect, useState } from "react";
import { getSbcActive } from "../../../utils/apiUtils";
import axios from 'axios';
import { Alert } from "@mui/material";
import IndvTicket from "./IndvTicket";

export default function Manage() {
  const [tickets, setTickets] = useState([]);
  const [userId, setUserId] = useState('');
  const [events, setEvents] = useState([]);
  const [eventId, setEventId] = useState(0)
  const [reload, setReload] = useState(false);
  // const url = 'http://localhost:4100'
  const url = 'https://dev.pmwevents.com'

  //alerts
  const [showAlert, setShowAlert] = useState(false);
  const [message, setMessage] = useState("");
  const [severity, setSeverity] = useState('');



  useEffect(() => {
    let id = localStorage.getItem('user');
    setUserId(id);
    getSbcActive()
      .then((data) => {
        console.log(data)
        setEvents(data)
        if (data.length === 1) {
          getTickets(data[0].eventId);
          setEventId(data[0].eventId)
        }
      }).catch((err) => {
        console.log(err)
        setMessage('Something went wrong, please try again')
        setSeverity('error')
        setShowAlert(true);
        setTimeout(() => {
          setShowAlert(false);
        }, 2000)
      })
  }, [reload])

  const getTickets = async (id) => {
    await axios.post(`${url}/sbc/get-tickets`, { eventId: id })
      .then((res) => {
        console.log(res.data)
        setTickets(res.data)
      }).catch((err) => {
        console.log(err)
      })
  }

  const rel = () => {
    setReload(!reload)
  }

  return (
    <main className="sbc__tickets">
      <Header />
      <section className='sbc__main'>
        <img src={logo} alt='logo' />
        <h1>Manage Ticket Pricing</h1>
        {tickets.length > 0 ? (
          <table>
            <thead>
              <tr>
                <th>Ticket Name</th>
                <th>Current Price</th>
                <th>Edit Price</th>
              </tr>
            </thead>
            <tbody>
              {tickets.map((ticket) => {
                return (
                  <IndvTicket ticket={ticket} eventId={eventId} userId={userId} rel={rel}/>
                )
              })}

            </tbody>
          </table>
        ) : (
          <strong>No tickets for this event yet</strong>
        )}
        {showAlert ? (
          <Alert severity={severity} sx={{ marginTop: '2%' }} variant="filled">
            {message}
          </Alert>
        ) : null}
      </section>
      <Footer />
    </main>
  )
}