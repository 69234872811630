// Use this route protection for parts that only Managers can see

import { Navigate } from "react-router-dom";

const ManagerProtect = ({children}) => {
  let id = localStorage.getItem('access')

  if (id != 1 && id != 3 && id != 4){
    return <Navigate to='/' replace/>
  }
  return children
}

export {ManagerProtect}