import '../styles/navFooter.scss';
import logo from '../images/logo.png';
import { useState } from 'react';
import MenuOpenIcon from '@mui/icons-material/MenuOpen';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import List from '@mui/material/List';
import Divider from '@mui/material/Divider';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import { useNavigate } from 'react-router-dom';

export default function Header() {
  const [open, setOpen] = useState(false);
  const navigate = useNavigate();
  const access = localStorage.getItem('access')

  const toggleDrawer = (newOpen) => () => {
    setOpen(newOpen);
  };

  const logOut = () => {
    localStorage.clear();
    navigate('/')
  }

  const DrawerList = (
    <Box sx={{ width: 250 }} role="presentation" onClick={toggleDrawer(false)}>
      <List>
        <ListItem disablePadding>
          <ListItemButton onClick={() => navigate('/manage-events')}>
            <ListItemText>Manage Events</ListItemText>
          </ListItemButton>
        </ListItem>
        {/* Manage Event Attendees */}
        <Divider />
        <ListItem disablePadding>
          <ListItemButton onClick={() => navigate('/manage-events/download')}>
            <ListItemText>Download Attendees</ListItemText>
          </ListItemButton>
        </ListItem>
        <ListItem disablePadding>
          <ListItemButton onClick={() => navigate('/manage-events/add-attendee')}>
            <ListItemText>Add Attendee</ListItemText>
          </ListItemButton>
        </ListItem>
        <ListItem disablePadding>
          <ListItemButton onClick={() => navigate('/manage-events/manage-attendee')}>
            <ListItemText>Manage Attendee</ListItemText>
          </ListItemButton>
        </ListItem>
        <ListItem disablePadding>
          <ListItemButton onClick={() => navigate('/manage-events/past-events')}>
            <ListItemText>Past Events</ListItemText>
          </ListItemButton>
        </ListItem>

        {/* END of manage event atendeees */}
        <Divider />
        {/* CREATE ZONE for Developers */}
        {access == 1 ? (
          <>
            <ListItem disablePadding>
              <ListItemButton onClick={() => navigate('/create-zone/create-event')}>
                <ListItemText>Create Event</ListItemText>
              </ListItemButton>
            </ListItem>
            <ListItem disablePadding>
              <ListItemButton onClick={() => navigate('/create-zone/create-user')}>
                <ListItemText>Create User</ListItemText>
              </ListItemButton>
            </ListItem>
            <ListItem disablePadding>
              <ListItemButton onClick={() => navigate('/create-zone/manage-user')}>
                <ListItemText>Manage User</ListItemText>
              </ListItemButton>
            </ListItem>

            <Divider />

          </>
        ) : null}
        {/* END OF CREATE ZONE */}
        <ListItem disablePadding>
          <ListItemButton onClick={() => navigate('/events-checkin')}>
            <ListItemText>Event Check-In</ListItemText>
          </ListItemButton>
        </ListItem>
        {access != 2 ? (
          <ListItem disablePadding>
            <ListItemButton onClick={() => navigate('/dashboard')}>
              <ListItemText>Dashboard</ListItemText>
            </ListItemButton>
          </ListItem>
        ) : null}
        <Divider />
        <ListItem disablePadding>
          <ListItemButton onClick={() => navigate('/sbc')}>
            <ListItemText>SBC</ListItemText>
          </ListItemButton>
        </ListItem>
        <Divider />
        <ListItem disablePadding>
          <ListItemButton onClick={logOut}>
            <ListItemText>Log Out</ListItemText>
          </ListItemButton>
        </ListItem>
      </List>
    </Box>
  );


  return (
    <nav>
      <img src={logo} alt='PMW' onClick={() => navigate('/menu')} />
      <MenuOpenIcon fontSize='large' sx={{ color: '#131b26' }} onClick={toggleDrawer(true)} />
      <Drawer open={open} onClose={toggleDrawer(false)}>
        {DrawerList}
      </Drawer>
    </nav>
  )
}