import CopyrightIcon from '@mui/icons-material/Copyright';

export default function Footer(){

  return(
    <footer>
      <p>PMW Events Ticketing System V1.2.6</p>
      <p><CopyrightIcon /> Paul McFadden Wealth Ltd.</p>
    </footer>
  )
}