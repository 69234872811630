import { Box, Typography, Button, Stack, Select, MenuItem, InputLabel, FormControl, Alert } from "@mui/material";
import axios from "axios";
import { useEffect, useState } from "react";
import { getSbcById } from "../../../../utils/apiUtils";


export default function Upgrade(props) {
  //const url = 'http://localhost:4100';
  const url = 'https://dev.pmwevents.com'
  const [tickets, setTickets] = useState([]);
  const [ticket, setTicket] = useState(0);
  const paid = parseInt(props.att.paidAmount);
  const [toPay, setToPay] = useState(0);
  const [ticketPrice, setTicketPrice] = useState('')
  const attendee = props.att
  const [newTicket, setNewTicket] = useState('')
  const [showModalAlert, setShowModalAlert] = useState(false);
  const [message, setMessage] = useState("");
  const [severity, setSeverity] = useState('');
  const [original, setOriginal] = useState(0);

  useEffect(() => {
    let event = parseInt(props.event)
    getSbcById(event)
      .then((data) => {
        const ticks = data
        setTickets(data)
        for (let t = 0; t < ticks.length; t++){
          if (ticks[t].ticketName === `${props.att.ticketType.substring((props.att.ticketType.indexOf('-') + 1),props.att.ticketType.length )}`){
            setOriginal(parseInt(ticks[t].ticketTypeId))
          }
        }
      }).catch((err) => {
        console.log(err)
      })
  }, [])

  const handleChange = (id) => {
    console.log(id)
    setTicket(parseInt(id))
    setTicketPrice(0)
    setToPay(0)
    setNewTicket('')

    for (let t = 0; t < tickets.length; t++) {
      if (tickets[t].ticketTypeId == id) {
        console.log("found ticket")
        setTicketPrice(parseInt(tickets[t].ticketPrice))
        setToPay(tickets[t].ticketPrice - paid)
        setNewTicket(`SBC-${tickets[t].ticketName}`)
      }
    }
  }

  const upgrade = () => {
    let data = {
      attendeeId: attendee.attendeeId,
      newTicket: newTicket,
      paid: (ticketPrice + (ticketPrice * 0.2)) - (paid + (paid * 0.2)),
      original: original,
      new: ticket,
      userId: props.userId,
    }


    axios.post(`${url}/sbc/upgrade-att`, data)
      .then((res) => {
        // console.log(res.data)
        props.rel()
        props.close()
      }).catch((err) => {
        console.log(err)
        setMessage('Something went wrong, please try again')
        setSeverity('error')
        setShowModalAlert(true)
        setTimeout(() => {
          setShowModalAlert(false)
        },2500)
      })
  }


  return (
    <Box sx={props.style}>
      <Typography id="modal-modal-title" variant="h6" component="h2">
        Select a ticket to upgrade {props.att.name} {props.att.lastName}?
      </Typography>
      <FormControl fullWidth>
        <InputLabel id="demo-simple-select-label">Select A Ticket Type</InputLabel>
        <Select
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          value={ticket}
          label="Age"
          onChange={(e) => handleChange(e.target.value)}
        >
          {tickets.map((ticket) => {
            return (
              <MenuItem value={ticket.ticketTypeId} key={ticket.ticketTypeId}>{ticket.ticketName}</MenuItem>
            )
          })}
        </Select>
      </FormControl>
      {toPay != 0 ? (
        <>
          <Typography variant="body1">Original Ticket Price: £{paid}</Typography>
          <Typography variant="body1">Paid: £{paid + (paid * 0.2)}</Typography>
          <Typography variant="body1">New Ticket Price: £{ticketPrice}</Typography>
          <Typography variant="body1">New Ticket VAT: £{(ticketPrice * 0.2).toFixed(2)}</Typography>
          <Typography variant="body1">To Pay: £{(ticketPrice + (ticketPrice * 0.2)) - (paid + (paid * 0.2))}</Typography>
        </>
      ) : null}

      {showModalAlert ? (
        <Alert severity={severity} sx={{ marginTop: '2%' }} variant="filled">
          {message}
        </Alert>
      ) : (
        <Stack direction="row" justifyContent="center" alignItems="center" spacing={2} sx={{ marginTop: '2%' }}>
          <Button color='error' variant='outlined' onClick={() => props.close()}>Return</Button>
          <Button color='success' variant='contained' onClick={upgrade}>Upgrade</Button>
        </Stack>
      )}

    </Box>
  )
}