import Footer from "../Footer";
import Header from "../Header";
import '../../styles/create.scss';
import { useState, useEffect } from "react";
import { Button } from "@mui/material";
import Alert from '@mui/material/Alert';
import axios from 'axios';
import CheckIcon from '@mui/icons-material/Check';
import Loader from '../../utils/Loader';
import { getEventTypes } from "../../utils/apiUtils";

export default function Create() {
  const today = new Date();
  const [types, setTypes] = useState([]);
  const [type, setType] = useState(0);
  const [date, setDate] = useState('');
  const [venue, setVenue] = useState('');
  const [capacity, setCapacity] = useState(0);
  const [disabled, setDisabled] = useState(false)
  //alerts
  const [showAlert, setShowAlert] = useState(false);
  const [showLoader, setShowLoader] = useState(false);
  const [message, setMessage] = useState("");
  const url = 'https://dev.pmwevents.com'
  const [showId, setShowId] = useState(false)
  const [action, setAction] = useState('')
  const id = localStorage.getItem('user')

  useEffect(() => {
    getEventTypes()
      .then((data) => {
        setTypes(data)
      }).catch((err) => {
        console.log(`Error getting event types: ${err}`)
      })
  }, [])



  const createEvent = async () => {
    setDisabled(true)
    if (type === 0 || date === '' || venue === "" || capacity === 0) {
      setMessage('All fields are required')
      setAction('warning')
      setShowAlert(true)
      setTimeout(() => {
        setShowAlert(false)
        setDisabled(false)
      }, 2500)
    } else {
    const event = {
      userId: id,
      type: type,
      date: date,
      venue: venue,
      capacity: capacity,
    }
    await axios.post(`${url}/events/add-event`, event)
      .then((res) => {
        setMessage(`Event created successfully, please copy event ID: ${res.data.id}`)
        setShowId(true)
      }).catch((err) => {
        setMessage('Something went wrong, please try again')
        setAction('error')
        setShowAlert(true)
        setTimeout(() => {
          setShowAlert(false)
          setDisabled(false)
        }, 2500)
      })
    }
  }

  return (
    <main className='create__event'>
      <Header />
      <section className="create__event__main">
        <h1>Please fill the form in</h1>
        <small>* All fields are required</small>
        <section className='create__form'>
          <label>Event Type:</label>
          {types.length > 0 ? (
            <select onChange={(e) => setType(parseInt(e.target.value))}>
              <option value={0}>--Please Select an Event Type--</option>
              {types.map((type) => {
                return <option key={type.eventType} value={type.eventType}>{type.name}</option>
              })}
            </select>
          ) : (
            <h4>NO TYPES OF EVENTS AVAILABLE</h4>
          )}
          <label>Event Date:</label>
          <input type='date' min={today} onChange={(e) => setDate(e.target.value)} />
          <label>Venue</label>
          <select onChange={(e) => setVenue(e.target.value)}>
            <option value="">--Please select a venue--</option>
            <option value="PMW HQ">PMW HQ</option>
            <option value="Edinburgh Apex">Edinburgh Apex</option>
            <option value="Hilton Cambridge Street">Glasgow Hilton Cambridge St</option>
          </select>
          <label>Venue Capacity</label>
          <input type='number' onChange={(e) => setCapacity(e.target.value)} pattern='[0-9]*' />
          {!showAlert ? (
            <div className='btn__loader'>
              <Button variant='outlined' color='primary' onClick={createEvent} disabled={disabled}>Create event</Button>
              {showLoader ? <Loader /> : null}
            </div>
          ) : (
            <Alert severity={action} sx={{ marginTop: '2%' }} variant="filled">
              {message}
            </Alert>
          )}
        </section>
        {showId ? (
          <Alert severity="success" sx={{ width: '80%', margin: 'auto', }} variant="filled" icon={<CheckIcon fontSize="inherit" />}>
            {message}
          </Alert>
        ) : null}


      </section>
      <Footer />

    </main>
  )
}