import { Box, Typography, Button, Stack } from "@mui/material";
import axios from "axios";


export default function Delete(props) {
    // const url = 'http://localhost:4100';
    const url = 'https://dev.pmwevents.com'


  const resendTicket = () => {
    let ticket = props.att.ticketType.substring((props.att.ticketType.indexOf('-') + 1),props.att.ticketType.length )

    let att = {
      id: props.att.attendeeId,
      ticket: ticket,
    }
    axios.put(`${url}/sbc/resend`, att)
    .then((res) => {
      props.rel()
      props.close()
    }).catch((err) => {
      console.log(err)
    })
  }

  return (
    <Box sx={props.style}>
      <Typography id="modal-modal-title" variant="h6" component="h2">
      Are you sure you want to resend the ticket to: {props.att.name} {props.att.lastName}?
      </Typography>
      <Stack direction="row" justifyContent="center" alignItems="center" spacing={2} sx={{ marginTop: '2%' }}>
        <Button color='error' variant='outlined' onClick={() => props.close()}>Return</Button>
        <Button color='success' variant='contained' onClick={resendTicket}>Resend</Button>
      </Stack>

    </Box>

  )
}