import Footer from "../Footer";
import Header from "../Header";
import PersonSearchIcon from '@mui/icons-material/PersonSearch';
import PersonAddAlt1Icon from '@mui/icons-material/PersonAddAlt1';
import '../../styles/sbc.scss';
import { useNavigate } from "react-router-dom";

export default function SbcAttendees() {
  const navigate = useNavigate()

  return (
    <main className="sbc__select">
      <Header />
      <section className='sbc__selection'>
        <h2>The Scottish Business Conference Attendees</h2>
        <section className='selection'>
          <article className='selection_card'
            onClick={() => navigate('/sbc/attendees/add')}
          >
            <PersonAddAlt1Icon fontSize='large' color='primary' />
            <strong>Add Attendees</strong>
          </article>
          <article className='selection_card'
            onClick={() => navigate('/sbc/attendees/manage')}
          >
            <PersonSearchIcon fontSize='large' color='primary' />
            <strong>Manage Attendees</strong>
          </article>
        </section>
      </section>
      <Footer />
    </main>
  )
}