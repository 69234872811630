// Use this route protection for parts that only developers can see

import { Navigate } from "react-router-dom";

const DeveloperProtected = ({children}) => {
  let id = localStorage.getItem('access')

  if (id != 1){
    return <Navigate to='/' replace/>
  }
  return children
}

export {DeveloperProtected}