import { BrowserRouter, Routes, Route } from 'react-router-dom';
import './App.css';
import Login from './components/login/Login';
import FirstLogin from './components/login/FirstLogin';
import Select from './components/menu/Select';
import { AllUsers } from './components/protectRoutes/AllUsers';
import { DeveloperProtected } from './components/protectRoutes/DeveloperProtected';
import { ManagerProtect } from './components/protectRoutes/ManagerProtect';
import Create from './components/menu/dev/Create';
import ManageEvents from './components/menu/ManageEvents';
import Download from './components/menu/Download';
import CancelAtt from './components/menu/admin/CancelAtt';
import CreateAtt from './components/menu/admin/CreateAtt';
import CheckIn from './components/menu/admin/CheckIn';
import Dash from './components/menu/dash/Dash';
import CreateZone from './components/menu/dev/CreateZone';
import CreateUser from './components/menu/dev/CreateUser';
import ManageUser from './components/menu/dev/ManageUser';
import PastEvents from './components/menu/admin/PastEvents';
import SbcSelect from './components/menu/sbc/SbcSelect';
import SbcAttendees from './components/menu/sbc/SbcAttendees';
import SbcAAd from './components/menu/sbc/SbcAdd';
import SbcManAt from './components/menu/sbc/SbcManAt';
import Manage from './components/menu/sbc/tickets/Manage';
import SbcDash from './components/menu/sbc/SbcDash';

function App() {
  return (
    <div className="App">
      <BrowserRouter>
        <Routes>
          <Route path='/' element={<Login />} />
          <Route path='password-change' element={<FirstLogin />} />
          <Route path='menu' element={
            <AllUsers>
              <Select />
            </AllUsers>
          }
          />
          {/* MANAGE EVENTS */}
          <Route path='manage-events' element={
            <AllUsers>
              <ManageEvents />
            </AllUsers>
          }
          />
          <Route path='manage-events/download' element={
            <AllUsers>
              <Download />
            </AllUsers>
          }
          />
          <Route path='manage-events/manage-attendee' element={
            <AllUsers>
              <CancelAtt />
            </AllUsers>
          }
          />
          <Route path='manage-events/add-attendee' element={
            <AllUsers>
              <CreateAtt />
            </AllUsers>
          }
          />
          <Route path='manage-events/past-events' element={
            <AllUsers>
              <PastEvents />
            </AllUsers>
          } />
          {/* END OF MANAGE EVENTS */}
          <Route path='events-checkin' element={
            <AllUsers>
              <CheckIn />
            </AllUsers>
          }
          />
          <Route path='dashboard' element={
            <ManagerProtect>
              <Dash />
            </ManagerProtect>
          }
          />
          {/* DEVELOPER ZONE */}
          <Route path='create-zone' element={
            <DeveloperProtected>
              <CreateZone />
            </DeveloperProtected>
          }
          />
          <Route path='create-zone/create-event' element={
            <DeveloperProtected>
              <Create />
            </DeveloperProtected>
          }
          />
          <Route path='create-zone/create-user' element={
            <DeveloperProtected>
              <CreateUser />
            </DeveloperProtected>
          }
          />
          <Route path='create-zone/manage-user' element={
            <DeveloperProtected>
              <ManageUser />
            </DeveloperProtected>
          }
          />
          {/* END DEVELOPER ZONE */}
          
          {/* SBC */}
          <Route path='sbc' element={
            <AllUsers>
              <SbcSelect />
            </AllUsers>
          } />
          <Route path='sbc/attendees' element={
            <AllUsers>
              <SbcAttendees />
            </AllUsers>
          } />
          <Route path='sbc/attendees/add' element={
            <AllUsers>
              <SbcAAd />
            </AllUsers>
          } />
          <Route path='sbc/attendees/manage' element={
            <AllUsers>
              <SbcManAt />
            </AllUsers>
          } />
          <Route path='sbc/tickets' element={
            <DeveloperProtected>
              <Manage />
            </DeveloperProtected>
          } />
          <Route path='sbc/dashboard' element={
            <ManagerProtect>
              <SbcDash />
            </ManagerProtect>
          } />
          {/* END OF SBC */}
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
