// Use this route protection for parts that all users can see

import { Navigate } from "react-router-dom";

const AllUsers = ({children}) => {
  let id = localStorage.getItem('access')

  if (!id){
    return <Navigate to='/' replace/>
  }
  return children
}

export {AllUsers}