import logo from '../images/logo.png';
import { TextField, Button } from '@mui/material'
import '../styles/login.scss';
import { useState, useEffect } from 'react';
import axios from 'axios';
import { encryptData } from '../utils/cryptoUtils';
import { useNavigate } from 'react-router-dom';



export default function FirstLogin() {
  const [userId, setUserId] = useState(0);
  const [accessLevel, setAccessLevel] = useState(0);
  const [password, setPassword] = useState('');
  const [confirmPass, setConfirmPass] = useState('');
  const [showAlert, setShowAlert] = useState(false);
  const [message, setMessage] = useState("");
  const url = 'https://dev.pmwevents.com';
  const navigate = useNavigate();

  useEffect(() => {
    const params = new URLSearchParams(window.location.search)
    const id = params.get('user')
    const level = params.get('access')
    setAccessLevel(parseInt(level))
    setUserId(parseInt(id))
  },[])

  const changePass = async() => {
    if (password === "" || confirmPass === ""){
      setMessage('Please insert password and confirm it')
      setShowAlert(true)
      setTimeout(() => {
        setShowAlert(false)
      },2500)
    } else if (password !== confirmPass){
      setMessage('Passwords do not match')
      setShowAlert(true)
      setTimeout(() => {
        setShowAlert(false)
      },2500)
    } else{
      let user = {
        userId: userId,
        password: encryptData(password, process.env.REACT_APP_USER_KEY),
      }
      console.log(user)
      axios.post(`${url}/login/change-password`, user)
      .then((res) => {
        localStorage.setItem('access',accessLevel)
        navigate('/menu')
      }).catch((err) => {
        console.log(err)
        setMessage(err.response.data.msg)
        setShowAlert(true)
        setTimeout(() => {
          setShowAlert(false)
        },2000)
      })
    }
  }

  const enter = (e) => {
    if (e.keyCode === 13){
      changePass()
    }
  }

  return(
    <main className='login__page'>
    <section className='login__form_wrapper'>
      <img src={logo} alt='PMW Wings' />
      <article className='login__form'>
        <h1>Please change your password</h1>
        <TextField label="Password" variant="standard" type="password" onChange={(e) => setPassword(e.target.value)} />
        <TextField label="Confirm Password" variant="standard" type="password" onChange={(e) => setConfirmPass(e.target.value)} onKeyDown={(e) => enter(e)}/>
        {!showAlert ? <Button variant='contained' color='primary' className='loginBtn' onClick={changePass}>Login</Button> : <p className='login__alert'>{message}</p>}
      </article>
    </section>
  </main>

  )
}