import { useState, useEffect } from 'react';
import Header from '../Header';
import Footer from '../Footer';
import '../../styles/create.scss';
import Alert from '@mui/material/Alert';
import axios from 'axios';
import { Button } from "@mui/material";


export default function CreateUser() {
  const [userName, setUserName] = useState('');
  const [email, setEmail] = useState('');
  const [disabled, setDisabled] = useState(false);
  const [userLevels, setUserLevels] = useState([]);
  const [accessLevel, setAccessLevel] = useState('');
  const [userId, setUserId] = useState('')
  //alerts
  const [showAlert, setShowAlert] = useState(false);
  const [severity, setSeverity] = useState('');
  const [message, setMessage] = useState("");

  const url = 'https://dev.pmwevents.com';

  useEffect(() => {
    let id = localStorage.getItem('user');
    setUserId(id);

    axios.get(`${url}/login/user-access`)
    .then((res) => {
      setUserLevels(res.data)
    }).catch((err) => {
      console.log(`Could not get the access level: ${err}`)
    })
  },[])

  const createUser = async() => {
    setDisabled(true)
    const data = {
      user: userName,
      email: email,
      accessLevel: parseInt(accessLevel),
      userId: userId,
    }

    console.log(data)

    if (userName === "" || email === "" || accessLevel === '' || accessLevel === '--Please Select One--'){
      setMessage('Please fill in all fields')
      setSeverity('warning')
      setShowAlert(true)
      setTimeout(() => {
        setShowAlert(false)
        setDisabled(false)
      },2000)
    } else{
      const data = {
        user: userName,
        email: email,
        accessLevel: parseInt(accessLevel),
        userId: userId
      }
      axios.post(`${url}/login/create-user`, data)
      .then((res) => {
        console.log(res.data)
        setMessage('User Created')
        setSeverity('success')
        setShowAlert(true)
        setTimeout(() => {
          let inputs = document.getElementsByTagName('input')
          for (let i = 0; i < inputs.length; i++){
            inputs[i].value=""
          }
          setShowAlert(false)
          setDisabled(false)
        },2000)
      }).catch((err) => {
        setMessage('Something went wrong, please try again')
        setSeverity('error')
        setShowAlert(true)
        setTimeout(() => {
          setShowAlert(false)
          setDisabled(false)
        },2000)
      })
    }
  }

  const enter = (e) => {
    if (e.keyCode === 13){
      createUser()
    }
  }



  return (
    <main className='create__event'>
      <Header />
      <section className='create__event__main'>
        <h1>Please fill in all details</h1>
        <small>* All fields are required</small>
        <section className='create__form'>
          <label>User Access Level</label>
          <select onChange={(e) => setAccessLevel(e.target.value)}>
            <option>--Please Select One--</option>
            {userLevels.map((level) => {
              return(
                <option value={level.id}>{level.name}</option>
              )
            })}
          </select>
          <label>Username</label>
          <input type='text' onChange={(e) => setUserName(e.target.value)}/>
          <label>Email</label>
          <input type='email' onChange={(e) => setEmail(e.target.value)} onKeyDown={enter}/>
        </section>
        {!showAlert ? (
          <Button variant='outlined' color='success' disabled={disabled} onClick={createUser}>Create User</Button>
        ) : (
          <Alert severity={severity} sx={{ width: '80%', margin: '0 auto', }} variant="filled">
            {message}
          </Alert>

        )}
      </section>
      <Footer />
    </main>
  )

}