import Footer from "./Footer";
import Header from "./Header";
import '../styles/manageEvents.scss';
import DownloadForOfflineIcon from '@mui/icons-material/DownloadForOffline';
import PersonAddAlt1Icon from '@mui/icons-material/PersonAddAlt1';
import PublishedWithChangesIcon from '@mui/icons-material/PublishedWithChanges';
import EventBusyIcon from '@mui/icons-material/EventBusy';
import { useNavigate } from "react-router-dom";

export default function ManageEvents () {
  const navigate = useNavigate();

  return(
    <main className="events__management">
      <Header />
      <section className="events__main">
        <article className="selection_card" onClick={() => navigate('/manage-events/download')}>
          <DownloadForOfflineIcon color='primary' fontSize="large"/>
          <strong>Download Attendees</strong>
        </article>
        <article className="selection_card" onClick={() => navigate('/manage-events/add-attendee')}>
          <PersonAddAlt1Icon color='primary' fontSize="large"/>
          <strong>Add Attendee</strong>
        </article>
        <article className="selection_card" onClick={() => navigate('/manage-events/manage-attendee')}>
          <PublishedWithChangesIcon color='primary' fontSize="large"/>
          <strong>Manage Attendee</strong>
        </article>
        <article className="selection_card" onClick={() => navigate('/manage-events/past-events')}>
          <EventBusyIcon color='primary' fontSize="large"/>
          <strong>Past Events</strong>
        </article>

      </section>
      <Footer/>
    </main>
  )
}