import '../../styles/selectMenu.scss';
import Footer from '../Footer';
import Header from '../Header';
import { useNavigate } from 'react-router-dom';
import NoteAddIcon from '@mui/icons-material/NoteAdd';
import PersonAddAltIcon from '@mui/icons-material/PersonAddAlt';
import SupervisorAccountIcon from '@mui/icons-material/SupervisorAccount';

export default function Select() {
  const navigate = useNavigate();


  return (
    <main className='select__main'>
      <Header />
      <section className='selection'>
      <article className='selection_card' onClick={() => navigate('/create-zone/create-event')}>
          <NoteAddIcon fontSize='large' color='primary'/>
          <strong>Create Event</strong>
        </article>

        <article className='selection_card' onClick={() => navigate('/create-zone/create-user')}>
          <PersonAddAltIcon fontSize='large' color='primary'/>
          <strong>Create User</strong>
        </article>
        <article className='selection_card'onClick={() => navigate('/create-zone/manage-user')}>
          <SupervisorAccountIcon fontSize='large' color='primary'/>
          <strong>Manage User</strong>
        </article>
      </section>
      <Footer />
    </main>
  )
}