import { getActiveEvents } from "../utils/apiUtils";
import Footer from "./Footer";
import Header from "./Header";
import { useState, useEffect } from "react";
import Loader from "../utils/Loader";
import Alert from '@mui/material/Alert';
import { getAttendees } from "../utils/apiUtils";
import { decryptData } from "../utils/cryptoUtils";
import { Button } from "@mui/material";
import DownloadIcon from '@mui/icons-material/Download';

//EXCEL EXPORTS
import * as FileSaver from 'file-saver';
import XLSX from 'sheetjs-style';


export default function Download() {
  const [events, setEvents] = useState([]);
  const [attendees, setAttendees] = useState([]);
  const [eventName, setEventName] = useState('');
  //alerts
  const [showAlert, setShowAlert] = useState(false);
  const [showLoader, setShowLoader] = useState(false);
  const [message, setMessage] = useState("");
  const [severity, setSeverity] = useState('');


  useEffect(() => {
    getActiveEvents().then((data) => {
      setEvents(data)
    }).catch((err) => {
      console.log(`Could not get events: ${err}`)
    })
  }, [])

  const getAttende = (eventId) => {
    setAttendees([]);
    setShowLoader(true)
    if (eventId === '-SELECT EVENT-') {
      setMessage('Please select an event')
      setSeverity('warning')
      setShowAlert(true);
      setShowLoader(false)
      setTimeout(() => {
        setShowAlert(false);
      }, 2000)
    } else {
      for (let e = 0; e < events.length; e++) {
        if (events[e].eventId == eventId) {
          setEventName(events[e].eventName)
        }
      }
      getAttendees(eventId)
        .then((data) => {
          if (data.length === 0) {
            setMessage('No tickets bought for this event')
            setSeverity('warning')
            setShowAlert(true);
            setShowLoader(false)
            setTimeout(() => {
              setShowAlert(false);
            }, 2500)

          } else {
            const atts = data
            //DECRYPTS THE DATA
            for (let a = 0; a < atts.length; a++) {
              atts[a].name = decryptData(atts[a].name, process.env.REACT_APP_ATT_KEY)
              atts[a].email = decryptData(atts[a].email, process.env.REACT_APP_ATT_KEY)
              atts[a].phone = decryptData(atts[a].phone, process.env.REACT_APP_ATT_KEY)
              atts[a].lastName = decryptData(atts[a].lastName, process.env.REACT_APP_ATT_KEY)
            }
            setAttendees(atts)
            setShowLoader(false)
          }
        }).catch((err) => {
          console.log(`Could not get attendees: ${err}`)
          setMessage('Something went wrong, please try again')
          setSeverity('error')
          setShowLoader(false)
          setShowAlert(true);
          setTimeout(() => {
            setShowAlert(false);
          }, 2000)
        })
    }
  }

  const exportExcel = () => {
    const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
    // const fileExtension = '.xlsx';

    const excelExportsData = [];

    attendees.forEach((obj) => {
      let newObj = {};

      Object.keys(obj).forEach((line) => {
        newObj[line] = obj[line];
      });
      excelExportsData.push(newObj);
    });

    const exportToExcel = async () => {
      const ws = XLSX.utils.json_to_sheet(excelExportsData);
      const wb = { Sheets: { data: ws }, SheetNames: ['data'] };
      const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
      const data = new Blob([excelBuffer], { type: fileType });

      FileSaver.saveAs(
        data, `Attendees for event: ${eventName}`
      )
    }

    exportToExcel();
  }

  return (
    <main className="events__management">
      <Header />
      <section className='download__main'>
        <h2>Please select an active event</h2>
        <select onChange={(e) => getAttende(e.target.value)}>
          <option>-SELECT EVENT-</option>
          {events.map((event) => {
            return (
              <option key={event.eventId} value={event.eventId}>{event.eventName}</option>
            )
          })}
        </select>
        {showLoader ? <Loader /> : null}
        {showAlert ? (
          <Alert severity={severity} sx={{ marginTop: '2%' }} variant="filled">
            {message}
          </Alert>
        ) : null}
        {attendees.length > 0 ? (
          <>
          <h2>Attendees: <span style={{color: '#1A3EA9'}}>{attendees.length}</span></h2>
            <table>
              <thead>
                <th>Order ID</th>
                <th>First Name</th>
                <th>Last Name</th>
                <th>Email</th>
                <th>Phone Number</th>
                <th>Ticket Type</th>
              </thead>
              <tbody>
                {attendees.map((att, index) => {
                  return (
                    <tr key={index}>
                      <td>{att.attendeeId}</td>
                      <td>{att.name}</td>
                      <td>{att.lastName}</td>
                      <td>{att.email}</td>
                      <td>{att.phone}</td>
                      <td>{att.ticketType}</td>
                    </tr>
                  )
                })}
              </tbody>
            </table>
            <Button color='primary' startIcon={<DownloadIcon />} variant="contained" onClick={exportExcel} sx={{marginBottom: '2%'}}>Download</Button>
          </>
        ) : null}
      </section>
      <Footer />
    </main>
  )
}