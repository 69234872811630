import Footer from "../Footer";
import Header from "../Header";
import { useState, useEffect } from "react";
import '../../styles/checking.scss';
import Loader from "../../utils/Loader";
import axios from "axios";
import { getAttendees } from "../../utils/apiUtils";
import { decryptData } from "../../utils/cryptoUtils";
import Alert from '@mui/material/Alert';
// MODAL
import ModalCheck from "./ModalCheck";
import Attendees from "./Attendees";
import { Button, Modal, Stack } from "@mui/material";
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import QrReader from 'react-web-qr-reader';
import moment from "moment";


const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 600,
  bgcolor: 'background.paper',
  border: 'none',
  borderRadius: "1rem",
  boxShadow: 24,
  p: 4,
  maxHeight: '90vh',
  '@media(max-width: 700px)': {
    width: '80vw',
    maxHeight: '80vh',
  }
};


export default function CheckIn() {
  const [events, setEvents] = useState([]);
  const [showLoader, setShowLoader] = useState(true);
  const url = 'https://dev.pmwevents.com'
  const [attendees, setAttendees] = useState([])
  const [noEvent, setNoEvent] = useState(false);
  const [attendee, setAttendee] = useState('');
  const [eventId, setEventId] = useState(0);
  const [userId, setUserId] = useState('');
  const [eventDate, setEventDate] = useState('');
  const [reload, setReload] = useState(false);
  const [checked, setChecked] = useState(0);
  const [searchArray, setSearchArray] = useState([]);
  const today = moment();
  // modal
  const [open, setOpen] = useState(false);
  const handleOpen = (id) => {
    setAttendee(id)
    setOpen(true)
  };
  const handleClose = () => setOpen(false);

  const [openConfirm, setOpenConfirm] = useState(false);
  const handleOpenConfirm = () => setOpenConfirm(true)
  const handleCloseConfirm = () => setOpenConfirm(false)

  const [openCheckin, setOpenCheckin] = useState(false);
  const handleOpenCheckin = () => setOpenCheckin(true)
  const handleCloseCheckin = () => setOpenCheckin(false)


  // alerts
  const [showAlert, setShowAlert] = useState(false);
  const [message, setMessage] = useState("");
  const [severity, setSeverity] = useState('');


  useEffect(() => {
    let id = localStorage.getItem('user')
    setUserId(id)

    axios.get(`${url}/checkin/get-events`)
      .then((res) => {
        if (res.data.events.length === 1) {
          setEventId(parseInt(res.data.events[0].eventId))
          setEventDate(res.data.events[0].eventDate)
          getAttendees(res.data.events[0].eventId)
            .then((data) => {
              let checked = 0
              const atts = data
              //DECRYPTS THE DATA
              for (let a = 0; a < atts.length; a++) {
                atts[a].name = decryptData(atts[a].name, process.env.REACT_APP_ATT_KEY)
                atts[a].lastName = decryptData(atts[a].lastName, process.env.REACT_APP_ATT_KEY)
                let checkins = atts[a].checkIn
                console.log(checkins)
                let diff = today.diff(moment(res.data.events[0].eventDate), 'days')
                let eventDay = `day${diff + 1}`
                if (checkins != null) {
                  for (let c = 0; c < checkins.length; c++) {
                    Object.keys(checkins[c]).forEach((key) => {
                      if (key === eventDay) {
                        checked++
                      }
                    })
                  }
                }
              }
              setAttendees(atts)
              setShowLoader(false)
              setChecked(checked)
            }).catch((err) => {
              console.log(`Could not get attendees: ${err}`)
            })
        } else if (res.data.events > 1) {
          setEvents(res.data.events)
          setShowLoader(false)
        } else {
          setShowLoader(false)
          setNoEvent(true)
        }
      }).catch((err) => {
        console.log(`Could not get the events: ${err}`)
      })
  }, [reload])

  const rel = () => {
    console.log('reloading')
    // setReload(!reload)
    window.location.reload()
  }

  const getAttende = (id) => {
    //TODO: set Event date and ID on a different way if more than one event running at one date
    setAttendees([]);
    getAttendees(id).then((data) => {
      const atts = data
      //DECRYPTS THE DATA
      for (let a = 0; a < atts.length; a++) {
        atts[a].name = decryptData(atts[a].name, process.env.REACT_APP_ATT_KEY)
        atts[a].lastName = decryptData(atts[a].lastName, process.env.REACT_APP_ATT_KEY)
      }
      setAttendees(atts)
    }).catch((err) => {
      console.log(`Could not get attendees: ${err}`)
    })
  }

  const confirmFinish = () => {
    let data = {
      eventId: eventId,
      userId: userId,
    }
    axios.post(`${url}/checkin/finish-checkin`, data)
      .then(async (res) => {
        setMessage('All remaining attendees marked as not attended')
        setSeverity('success')
        setShowAlert(true)
        setTimeout(() => {
          setShowAlert(false)
          handleCloseConfirm()
          rel()
        }, 2500)
      }).catch((err) => {
        console.log(err)
        setMessage('Something went wrong, please try again')
        setSeverity('error')
        setShowAlert(true)
        setTimeout(() => {
          setShowAlert(false)
        }, 2000)

      })
  }

  const handleScan = async (result) => {
    console.log(result.data)
    let checkInData = {
      user: userId,
      attendeeId: result.data
    }
    axios.post(`${url}/checkin`, checkInData)
      .then((res) => {
        console.log(res.data)
        setMessage('Checked in')
        setShowAlert(true)
        setSeverity('success')
        rel()
        setTimeout(() => {
          setShowAlert(false)
          handleCloseCheckin()
        }, 2000)
      }).catch((err) => {
        console.log(`Could not check the attendee in: ${err}`)
        setMessage('Something went wrong, please try again')
        setShowAlert(true)
        setSeverity('error')
        setTimeout(() => {
          setShowAlert(false)
        }, 2000)

      })
  }

  const handleError = (error) => {
    console.log(error)
  }

  const search = (searchParam) => {
    let searching = searchParam;
    let localArray = [];

    if (searching.length > 2) {
      for (let a = 0; a < attendees.length; a++) {
        if (attendees[a].name.toLowerCase().includes(searching.toLowerCase())) {
          localArray.push(attendees[a]);
        }
      }
    }
    setSearchArray(localArray);
  }

  return (
    <main className='checkin__main'>
      <Header />
      <section className='checkin'>
        {showLoader ? (
          <>
            <h2>Searching for live events</h2>
            <Loader />
          </>
        ) : (
          noEvent ? (
            <h2>No active events to check in</h2>
          ) : (
            <>
              {events.length > 0 ? (
                <select onChange={(e) => getAttende(e.target.value)}>
                  <option>-SELECT EVENT-</option>
                  {events.map((event) => {
                    return (
                      <option key={event.eventId} value={event.eventId}>{event.eventName}</option>
                    )
                  })}
                </select>
              ) : null}
              {attendees.length > 0 ? (
                <>
                  <h2>Checked-In: <span>{checked}</span></h2>
                  <input type="text" placeholder="Search attendees" onChange={(e) => search(e.target.value)} />
                  {searchArray.length > 0 ? (
                    <table>
                      <thead>
                        <tr>
                          <th>ID</th>
                          <th>First Name</th>
                          <th>Last Name</th>
                          <th>Ticket Type</th>
                          <th>Check-IN</th>
                        </tr>
                      </thead>
                      <tbody>
                        {searchArray.map((attendee) => {
                          return (
                            <Attendees att={attendee} handleOpen={handleOpen} date={eventDate} />
                          )
                        })}
                      </tbody>
                    </table>

                  ) : null}
                  <Button variant='contained' color='primary' onClick={handleOpenCheckin}>Check-IN</Button>
                  <table>
                    <thead>
                      <tr>
                        <th>ID</th>
                        <th>First Name</th>
                        <th>Last Name</th>
                        <th>Ticket Type</th>
                        <th>Check-IN</th>
                      </tr>
                    </thead>
                    <tbody>
                      {attendees.map((attendee) => {
                        return (
                          <Attendees att={attendee} handleOpen={handleOpen} date={eventDate} />
                        )
                      })}
                    </tbody>
                  </table>
                  <Button variant='contained' color='success' onClick={handleOpenConfirm} sx={{ margin: '1% auto 2% auto' }}>Finish Check-in</Button>
                </>
              ) : (
                <Alert severity='warning' sx={{ width: '90%', margin: '2% auto' }} variant="filled">
                  No attendees for this event
                </Alert>
              )}
            </>
          )
        )}
      </section>
      <Modal
        open={open}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <ModalCheck handleClose={handleClose} attendee={attendee} rel={rel} />
      </Modal>
      <Modal
        open={openConfirm}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            Please confirm that the doors are now closed
          </Typography>
          {showAlert ? (
            <Alert severity={severity} sx={{ marginTop: '2%' }} variant="filled">
              {message}
            </Alert>
          ) : (
            <Stack direction="row" justifyContent="center" alignItems="center" spacing={2} sx={{ marginTop: '2%' }}>
              <Button color='error' variant='outlined' onClick={handleCloseConfirm}>Cancel</Button>
              <Button color='success' variant='contained' onClick={confirmFinish}>Confirm</Button>
            </Stack>
          )}
        </Box>
      </Modal>

      <Modal
        open={openCheckin}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            Check Attendee In
          </Typography>
          {showAlert ? (
            <Alert severity={severity} sx={{ marginTop: '2%' }} variant="filled">
              {message}
            </Alert>
          ) : (
            <>
              <QrReader
                delay={500}
                style={{ videoWidth: 320 }}
                onError={handleError}
                onScan={handleScan}
              />
              <Stack direction="row" justifyContent="center" alignItems="center" spacing={2} sx={{ marginTop: '2%' }}>
                <Button color='error' variant='outlined' onClick={handleCloseCheckin}>Cancel</Button>
              </Stack>
            </>
          )}
        </Box>
      </Modal>


      <Footer />
    </main>
  )
}