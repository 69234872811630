import { IconButton } from "@mui/material";
import QrCodeIcon from '@mui/icons-material/QrCode';
import { useEffect, useState } from "react";
import moment from 'moment';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';


export default function Attendees(props) {
  const attendee = props.att
  const today = moment()
  const [checked, setChecked] = useState(false)


  useEffect(() => {
    // console.log(props.att.checkIn)
    let difference = today.diff(moment(props.date), 'days')
    let eventDay = `day${difference + 1}`
    let checkins = props.att.checkIn

    if (checkins != null) {
      for (let c = 0; c < checkins.length; c++) {
        Object.keys(checkins[c]).forEach((key) => {
          if (key === eventDay) {
            setChecked(true)
          }
        })
      }
    }
  }, [])

  return (
    <tr>
      <td>{attendee.attendeeId}</td>
      <td>{attendee.name}</td>
      <td>{attendee.lastName}</td>
      <td>{attendee.ticketType}</td>
      {checked ? (
        <td><CheckCircleIcon color='success' fontSize="large" /></td>
      ) : (
        <td><IconButton><QrCodeIcon fontSize="large" color="primary" onClick={() => props.handleOpen(attendee.attendeeId)} /></IconButton></td>
      )}
    </tr>

  )
}