import CryptoJS from "crypto-js";

const encryptData = (data, key) => {
  return CryptoJS.AES.encrypt(data, key).toString();
};

const decryptData = (data, key) => {
  const bytes = CryptoJS.AES.decrypt(data, key);
  return bytes.toString(CryptoJS.enc.Utf8);
};

export {encryptData, decryptData};