import Footer from "../Footer";
import Header from "../Header";
import { useState, useEffect } from 'react';
import '../../styles/dash.scss';
import axios from "axios";
import ReactApexChart from "react-apexcharts";


export default function SbcDash() {
  // const url = "http://localhost:4100";
  const url = 'https://dev.pmwevents.com'

  const [tickets, setTickets] = useState([])
  const [sales, setSales] = useState(0);
  const [totalTickets, setTotalTickets] = useState(0); 
  const [totalAttendees, setTotalAttendees] = useState(0);
  const [salesData, setSalesData] = useState([]);
  const [adminSales, setAdminSales] = useState([]);
  const [datesData, setDatesData] = useState([]);


  useEffect(() => {
    axios.get(`${url}/sbc/dashboard`)
      .then((res) => {
        console.log(res.data)
        setTickets(res.data.tickets)
        setSales(res.data.sales)
        setTotalTickets(res.data.attendees)
        setTotalAttendees(res.data.totalAttendees)
        setSalesData(res.data.web)
        setAdminSales(res.data.admin)
        setDatesData(res.data.dates)
      }).catch((err) => {
        console.log(err)
      })
  }, [])

    // CHART
    let salesChart = {
      series: [{
        name: 'Web Sales',
        data: salesData
      }, {
        name: 'Admin Tickets',
        data: adminSales
      }],
      options: {
        chart: {
          type: 'bar',
          height: 350,
          width: 750,
          // background: "#fff",
        },
        plotOptions: {
          bar: {
            horizontal: false,
            columnWidth: '55%',
            endingShape: 'rounded'
          },
        },
        dataLabels: {
          enabled: false
        },
        stroke: {
          show: true,
          width: 2,
          colors: ['transparent']
        },
        xaxis: {
          categories: datesData,
        },
        yaxis: {
          title: {
            text: 'Ticket Sales per Day'
          }
        },
        fill: {
          opacity: 1
        },
        tooltip: {
          y: {
            formatter: function (val) {
              return val + "  Tickets"
            }
          }
        }
      },
      responsive: [{
        breakpoint: 750,
        options: {
          width: 450
        }
      }],
    }
  

  return (
    <main className="dash">
      <Header />
      <section className="dash__main">
      <h2>Data for Scottish Business Conference</h2>

        <div className="dash__data">
          <section className="dash__row">
            <div className="dash__card">
              <h3>£{parseFloat(sales).toFixed(2)}</h3>
              <h6>Sales</h6>
            </div>
            <div className="dash__card">
              <h3>{totalTickets}</h3>
              <h6>Total Ticket Sales</h6>
            </div>
            <div className="dash__card">
              <h3>{totalAttendees}</h3>
              <h6>Total Attendees</h6>
            </div>

          </section>
          <table>
              <thead>
                <th>Ticket Name</th>
                <th>Ticket Sales</th>
              </thead>
              <tbody>
                {tickets.map((ticket) => {
                  return(
                    <tr>
                      <td>{ticket.ticketName}</td>
                      <td>{ticket.ticketSales}</td>
                    </tr>
                  )
                })}
              </tbody>
            </table>

            <ReactApexChart
                options={salesChart.options}
                series={salesChart.series}
                type="bar"
                width={800}
              />
        </div>
      </section>
      <Footer />
    </main>
  )
}