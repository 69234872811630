import { useState, useEffect } from "react";
import '../../styles/create.scss';
import Header from "../Header";
import Footer from "../Footer";
import axios from "axios";
import Users from "./Users";
import { Alert } from "@mui/material";

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 600,
  boxShadow: 24,
  p: 4,
}


export default function ManageUser() {
  const [users, setUsers] = useState([]);
  const [userId, setUserId] = useState('')
  const url = 'https://dev.pmwevents.com';
  //alerts
  const [showAlert, setShowAlert] = useState(false);
  const [severity, setSeverity] = useState('');
  const [message, setMessage] = useState("");
  const [reload, setReload] = useState(false)

  useEffect(() => {
    let id = localStorage.getItem('user');
    setUserId(id);

    axios.get(`${url}/login/get-users`)
      .then((res) => {
        console.log(res.data)
        setUsers(res.data)
      }).catch((err) => {
        console.log(err)
      })
  }, [])

  const manageAlert = (mess, sev) => {
    setMessage(mess)
    setSeverity(sev)
    setShowAlert(true)
    setTimeout(() => {
      setShowAlert(false)
    },2000)
  }

  const rel = () => {
    window.location.reload()
  }

  return (
    <main className="create__event">
      <Header />
      <section className="create__event__main">
        <h1>Manage Users</h1>
        <table>
          <thead>
            <tr>
              <th>User</th>
              <th>Un-block/Password reset</th>
              <th>Delete</th>
            </tr>
          </thead>
          <tbody>
            {users.map((user) => {
              return <Users user={user} userId={userId} manageAlert={manageAlert} rel={rel}/>
            })}
          </tbody>
        </table>
        {showAlert ? (
          <Alert severity={severity} sx={style} variant="filled">
            {message}
          </Alert>
        ) : null}

      </section>
      <Footer />
    </main>
  )

}