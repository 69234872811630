import Footer from "../Footer";
import Header from "../Header";
import { useEffect, useState } from "react";
import { getSbcActive, getSbcById } from "../../utils/apiUtils";
import sbc from '../../images/sbc_logo.webp';
import moment from "moment";
import { Button, Alert } from "@mui/material";
import axios from "axios";

export default function SbcAAd() {
  // const url = 'http://localhost:4100';
  const url = 'https://dev.pmwevents.com'

  const [events, setEvents] = useState([]);
  const [eventId, setEventId] = useState('')
  const [userId, setUserId] = useState('');
  const [tickets, setTickets] = useState([]);
  const [attendees, setAttendees] = useState([]);
  const [quantity, setQuantity] = useState(0);
  const [paid, setPaid] = useState(false);
  const [ticketName, setTicketName] = useState('')
  const [form, setForm] = useState(false);
  const [ticketPrice, setTicketPrice] = useState(0);
  const [ticket, setTicket] = useState(0);
  const [total, setTotal] = useState(0)
  //alerts
  const [disable, setDisable] = useState(false);
  const [showAlert, setShowAlert] = useState(false);
  const [showLoader, setShowLoader] = useState(false);
  const [message, setMessage] = useState("");
  const [severity, setSeverity] = useState('');
  const [reload, setReload] = useState(false);



  // TODO: create selection of SBC


  useEffect(() => {
    let id = localStorage.getItem('user');
    setUserId(id);
    getSbcActive()
      .then((data) => {
        setEvents(data)
        if (data.length === 1) {
          setEventId(data[0].eventId)
          getEvent(data[0].eventId)
          setForm(true)
        }
      }).catch((err) => {
        console.log(err)
      })

  }, [reload])

  const getEvent = async (id) => {
    await getSbcById(id)
      .then((data) => {
        setTickets(data)
      }).catch((err) => {
        console.log(err)
      })
  }

  const handleQuantity = (quantity) => {
    setQuantity(parseInt(quantity))
    setTotal(parseInt(quantity) * parseInt(ticketPrice))
  }

  const handleAttendee = async (index, field, value) => {
    let localArray = attendees

    if (localArray.length < parseInt(index) + 1) {
      localArray.push({ name: '', lastName: '', email: '', phone: '', address: '', postcode: '' })
      localArray[localArray.length - 1][field] = value
    } else {
      localArray[index][field] = value
    }

    setAttendees(localArray)
  }

  const handleTicketType = (id) => {
    if (id === '--Please Select One--' || "") {
      setSeverity('warning');
      setMessage('Please select a ticket type')
      setShowAlert(true)
      setTimeout(() => {
        setShowAlert(false)
      }, 2500)
    } else {
      setTicket(parseInt(id))
      for (let t = 0; t < tickets.length; t++) {
        if (id == tickets[t].ticketTypeId) {
          setTicketPrice(parseInt(tickets[t].ticketPrice));
          setTicketName(tickets[t].ticketName);
        }
      }
    }
  }

  const createAttendee = () => {
    setDisable(true)
    let send = true;

    if (ticket === "" || ticket === "--Please Select One--" || ticket === 0){
      send = false;
      setSeverity('warning');
      setMessage('Please select a ticket type');
      setShowAlert(true);
      setTimeout(() => {
        setShowAlert(false);
        setDisable(false);
      }, 2500)
    } else{
      for (let a = 0; a < attendees.length; a++) {
        Object.keys(attendees[a]).forEach((field) => {
          if (attendees[a][field] === '') {
            setShowLoader(false);
            setMessage('All fields are mandatory');
            setSeverity('warning');
            setShowAlert(true);
            setShowLoader(false);
            setTimeout(() => {
              setShowAlert(false);
              setDisable(false);
            }, 2000)
            send = false;
          } 
          else {
            attendees[a].eventId = parseInt(eventId);
            attendees[a].attendeeId = `${parseInt(eventId)}-${ticket}-${moment().format('DD-MM-YYYY')}-adm-${Math.floor(1000 + Math.random() * 9000)}`
            attendees[a].paidAmount = ticketPrice;
            attendees[a].ticketType = ticketName;
          }
        })
      }  
    }
    // If all fields are filled in
    if (send) {
      let data = {
        userId: userId,
        attendees: attendees,
        ticketId: ticket,
        eventId: eventId,
      }
      axios.post(`${url}/sbc/add-att`, data)
        .then((res) => {
          setShowLoader(false)
          setMessage('Attendee(s) added to the event, they will receive their email with the ticket soon')
          setSeverity('success')
          setShowAlert(true);
          setTimeout(() => {
            setShowAlert(false);
            setDisable(false)
            setAttendees([]);
            setQuantity(1);
            setReload(!reload)
            let inputs = document.getElementsByTagName('input')
            for (let i = 0; i < inputs.length; i++){
              inputs[i].value=""
            }
            setQuantity(0);
            setTotal(0);
          }, 2000)

        }).catch((err) => {
          console.log(err)
          setShowLoader(false)
          setMessage('Something went wrong, please try again')
          setSeverity('error')
          setShowLoader(false)
          setShowAlert(true);
          setTimeout(() => {
            setShowAlert(false);
            setDisable(false)
          }, 2000)

        })
    }
  }



  return (
    <main className="sbc__select">
      <Header />
      <section className="sbc__selection">
        <img src={sbc} alt="sbc" />
        {events.length === 1 ? (
          <>
            <h1>Adding sbc attendees for {moment(events[0].eventDate).format('DD-MM-YYYY')}</h1>
          </>
        ) : (
          <select>
            <option>Select and event</option>
          </select>
        )}

        {form ? (
          <section className='add__form'>
            <h3>All fields are mandatory</h3>
            <label>Ticket Type</label>
            <select onChange={(e) => handleTicketType(e.target.value)}>
              <option>--Please Select One--</option>
              {tickets.map((ticket) => {
                let sold = "";
                if (ticket.maxTickets == ticket.sales){
                  sold = "SOLD OUT"
                }
                return (
                  <option key={ticket.ticketTypeId} value={ticket.ticketTypeId}>{ticket.ticketName} {sold}</option>
                )
              })}
            </select>
            <label>Quantity</label>
            <select onChange={(e) => handleQuantity(e.target.value)}>
              <option>--Select Quantity--</option>
              <option>1</option>
              <option>2</option>
              <option>3</option>
              <option>4</option>
            </select>
            {quantity > 0 ? (
              Array.from(Array(quantity)).map((att, index) => {
                return (
                  <>
                    <h6>Attendee {index + 1}</h6>
                    <label>First Name</label>
                    <input type='text' onChange={(e) => handleAttendee(index, 'name', e.target.value)} />
                    <label>Last Name</label>
                    <input type='text' onChange={(e) => handleAttendee(index, 'lastName', e.target.value)} />
                    <label>Email</label>
                    <input type='email' onChange={(e) => handleAttendee(index, 'email', e.target.value)} />
                    <label>Telephone</label>
                    <input type='text' onChange={(e) => handleAttendee(index, 'phone', e.target.value)} />
                    <label>Address</label>
                    <input type='text' onChange={(e) => handleAttendee(index, 'address', e.target.value)} />
                    <label>Postcode</label>
                    <input type='text' onChange={(e) => handleAttendee(index, 'postcode', e.target.value)} />
                  </>
                )
              })
            ) : null}
            {total !== 0 ? (
              <>
                <p>Total Tickets: £{total.toFixed(2)}</p>
                <p>VAT = £{(total * 0.2).toFixed(2)}</p>
                <strong>Total to pay = £{((total * 0.2) + total).toFixed(2)}</strong>
              </>
            ) : null}
            <Button sx={{ alignSelf: 'center', margin: '2% auto' }} variant='contained' color='primary' disabled={disable} onClick={createAttendee}>Add Attendee</Button>
          </section>
        ) : null}
        {showAlert ? (
          <Alert severity={severity} sx={{ marginTop: '2%' }} variant="filled">
            {message}
          </Alert>
        ) : null}


      </section>
      <Footer />
    </main>
  )
}