import LockResetIcon from '@mui/icons-material/LockReset';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import axios from 'axios';
import { useState } from 'react';
import { Button, IconButton, Stack, Modal, Box, Typography } from "@mui/material";

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 600,
  bgcolor: 'background.paper',
  border: 'none',
  boxShadow: 24,
  p: 4,
  borderRadius: '1rem',
  '@media(max-width: 700px)': {
    width: '80vw',
    maxHeight: '80vh',
  }
};



export default function Users(props) {
  const user = props.user;
  const url = 'https://dev.pmwevents.com';

  const [color, setColor] = useState('primary')
  //modal
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);



  const resetPass = () => {
    setColor('warning')
    let data = {
      user: props.user.userId,
      userId: props.userId,
      email: props.user.email,
      userName: props.user.userName,
    }

    axios.put(`${url}/login/reset-unblock`, data)
      .then((res) => {
        setColor('primary')
        props.manageAlert('Password reset, the user will receive an email soon', 'success')
      }).catch((err) => {
        setColor('primary')
        console.log(err)
        props.manageAlert('Something went wrong, please try again', 'error')
      })
  }

  const deleteUser = () => {
    let data = {
      user: props.user.userId,
      userId: props.userId,
    }

    axios.post(`${url}/login/delete-user`, data)
    .then((res) => {
      console.log(res)
      handleClose()
      props.rel()
    }).catch((err) => {
      props.manageAlert('Something went wrong, please try again', 'error')
    })

  }

  return (
    <tr>
      <td>{user.userName}</td>
      <td><IconButton onClick={resetPass}><LockResetIcon color={color} fontSize='large' /></IconButton></td>
      <td><IconButton onClick={handleOpen}><DeleteForeverIcon color='primary' fontSize='large' /></IconButton></td>
      <Modal
        open={open}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            Are you sure you want to Delete {user.userName}?
          </Typography>
          <Stack direction="row" justifyContent="center" alignItems="center" spacing={2} sx={{ marginTop: '2%' }}>
            <Button color='error' variant='outlined' onClick={handleClose}>Return</Button>
            <Button color='success' variant='contained' onClick={deleteUser}>Delete</Button>
          </Stack>
        </Box>
      </Modal>

    </tr>
  )
}