import { useEffect, useState } from "react"
import { IconButton } from "@mui/material"
import EditIcon from '@mui/icons-material/Edit';
import { Box, Button, Typography, Modal, Stack, Alert, TextField } from '@mui/material';
import axios from "axios";

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 600,
  bgcolor: 'background.paper',
  border: 'none',
  boxShadow: 24,
  p: 4,
  borderRadius: '1rem',
  '@media(max-width: 700px)': {
    width: '80vw',
    maxHeight: '80vh',
    overflowY: 'scroll',
  }
};


export default function IndvTicket(props) {
  const [ticket, setTicket] = useState({});
  const eventId = props.eventId;
  const userId = props.userId;
  const [showModalAlert, setShowModalAlert] = useState(false);
  const [message, setMessage] = useState("");
  const [severity, setSeverity] = useState('');
  const [showChange, setShowChange] = useState(false);
  // const url = 'http://localhost:4100'
  const url = 'https://dev.pmwevents.com'

  // modal
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);


  useEffect(() => {
    setTicket(props.ticket)
  }, [props.ticket])

  const handlePrice = (price) => {
    ticket.ticketPrice = price;
  }

  const editPrice = () => {
    let data = {
      user: userId,
      event: eventId,
      ticket: ticket,
    }
    axios.put(`${url}/sbc/edit-price`, data)
    .then((res) => {
      console.log(res.data)
      setMessage('Price updated')
      setSeverity('success')
      setShowModalAlert(true)
      setTimeout(() => {
        setShowModalAlert(false)
        props.rel()
        handleClose()
      },2500)

    }).catch((err) => {
      console.log(err)
      setMessage('Something went wrong, please try again')
      setSeverity('error')
      setShowModalAlert(true)
      setTimeout(() => {
        setShowModalAlert(false)
      },2500)
    })

  }



  return (
    <>
      <tr>
        <td>{ticket.ticketName}</td>
        <td>{ticket.ticketPrice}</td>
        <td><IconButton onClick={handleOpen}><EditIcon color="primary" /></IconButton></td>
      </tr>
      <Modal
        open={open}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            Are you sure you want to change the price of:
          </Typography>
          <Typography id="modal-modal-description" sx={{ mt: 2 }}>
            Ticket: {ticket.ticketName}
          </Typography>
          <Typography id="modal-modal-description" sx={{ mt: 2 }}>
            Current Price: {ticket.ticketPrice} <EditIcon color='primary' fontSize="small" onClick={() => setShowChange(!showChange)} />
          </Typography>
          {showChange ? (
            <TextField label="New Price" variant="standard" onChange={(e) => handlePrice(e.target.value)} />
          ) : null}
          {showModalAlert ? (
            <Alert severity={severity} sx={{ marginTop: '2%' }} variant="filled">
              {message}
            </Alert>
          ) : (
            <Stack direction="row" justifyContent="center" alignItems="center" spacing={2} sx={{ marginTop: '2%' }}>
              <Button color='error' variant='outlined' onClick={handleClose}>Return</Button>
              <Button color='success' variant='contained' onClick={editPrice}>Edit</Button>
            </Stack>
          )}
        </Box>
      </Modal>
    </>
  )
}