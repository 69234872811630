import '../styles/selectMenu.scss';
import Footer from './Footer';
import Header from './Header';
import { useNavigate } from 'react-router-dom';
import { useEffect, useState } from 'react';
import EditCalendarIcon from '@mui/icons-material/EditCalendar';
import AddTaskIcon from '@mui/icons-material/AddTask';
import NoteAddIcon from '@mui/icons-material/NoteAdd';
import InsightsIcon from '@mui/icons-material/Insights';

export default function Select() {
  const navigate = useNavigate();
  const [access, setAccess] = useState(0)

  useEffect(() => {
    let id = localStorage.getItem('access')
    setAccess(parseInt(id))
  }, [])

  return (
    <main className='select__main'>
      <Header />
      <section className='selection'>
        <article className='selection_card'onClick={() => navigate('/manage-events')}>
          <EditCalendarIcon fontSize='large' color='primary'/>
          <strong>Manage Event</strong>
        </article>
        <article className='selection_card' onClick={() => navigate('/events-checkin')}>
          <AddTaskIcon fontSize='large' color='primary'/>
          <strong>Event Check-In</strong>
        </article>
        {access === 1 ? <article className='selection_card' onClick={() => navigate('/create-zone')}>
          <NoteAddIcon fontSize='large' color='primary'/>
          <strong>Create Zone</strong>
        </article> : null}
        {access !== 2 ? <article className='selection_card' onClick = {() => navigate('/dashboard')}>
          <InsightsIcon fontSize='large' color='primary'/>
          <strong>Dashboard</strong>
        </article> : null}


      </section>
      <Footer />
    </main>
  )
}